import React from "react";
import "./TopHeadingStyles.css";

const TopHeading = ({ heading }) => {
  return (
    <div className="heading-main-container">
      <h1 className="heading-header-text">{heading}</h1>
      <h4 className="heading-subText">Atharava Vidyalaya / {heading}</h4>
    </div>
  );
};

export default TopHeading;
