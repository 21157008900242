import React from "react";
import TopHeading from "../../components/TopHeading";
import { ContactUs } from "../ContactUs";
import "./CodeOfConductStyles.css";

export const CodeOfConduct = () => {
  return (
    <div id="codeOfConduct" className="coc-screen-main-container">
      <TopHeading heading="Code Of Conduct" />
      <a href="https://ibb.co/gSzzYq7">
        <img
          src="https://i.ibb.co/rx4498t/teacher-holding-english-class.jpg"
          alt="teacher-holding-english-class"
          border="0"
          className="coc-img-style"
        />
      </a>
      <div className="code-of-conduct-content">
        <Card title="Personal Hygiene/Appearance/Dress">
          <ul>
            <li>
              Students are expected to maintain proper hygiene i.e. They should
              trim and clean nails, wear fresh clean clothes and carry a
              handkerchief at all times.
            </li>
            <li>
              Boys are required to have well-trimmed hair properly brushed and
              combed. Sporting long hair/hairstyles is strictly forbidden.
            </li>
            <li>
              Girls with long hair must have it tied up with a white ribbon &
              white hairband. Ornamental hair accessories and heavy jewellery
              are not allowed.
            </li>
            <li>
              Shoes should be well-polished, uniforms starched and well-ironed
              to ensure a smart, well-groomed & presentable personality.
            </li>
            <li>
              Sports uniforms must be worn on scheduled days (Wednesday and
              Saturday).
            </li>
            <li>
              Failure to comply with dress regulations may result in warnings.
              He/She will then be marked absent.
            </li>
          </ul>
        </Card>
        <Card title="Behavior/Deportment/Speech">
          <ul>
            <li>
              Good manners, courtesy and politeness being the hallmark of good
              students, he/she is expected to greet all staff members, visitors
              and elders showing due respect at all times.
            </li>
            <li>
              Use of vulgar and objectionable language, display of violent
              behaviour etc. will not be allowed. During class hours, absolute
              silence must be observed.
            </li>
            <li>
              Students must show consideration to all members of society.
              His/her speech should not hurt or offend anyone.
            </li>
            <li>
              Misuse of school property, scribbling on walls, furniture,
              bulletin boards, toilet walls, etc. will be severely dealt with.
              Breakages of any kind are subject to a fine and/or payment for
              replacement repairs.
            </li>
            <li>
              School premises must be kept clean. Litter of any kind must be
              thrown in dustbins/waste paper baskets.
            </li>
          </ul>
        </Card>
        <Card title="Attendance/Leave/Absence">
          <ul>
            <li>
              Students should reach the school 5 minutes before the bell rings.
              Late comers will be marked PL (present but late). For three
              consecutive PL's strict action will be taken.
            </li>
            <li>Attendance to the daily assembly/prayer is a must.</li>
            <li>
              Students are required to be present on 80 percent of the total
              working days in an academic year for the grant of terms and to be
              eligible to appear for the Annual Examinations. In case, the
              attendance is inadequate, the student will not be allowed for the
              examination.
            </li>
            <li>
              Leave may be taken only after obtaining the higher authority's
              sanction in writing.
            </li>
            <li>
              Absence on medical grounds need to be supported by a Medical
              Certificate from a Registered Medical Practitioner from Vadodara.
            </li>
            <li>
              In case, a student is absent from the School for 90 days
              continuously, his/her name will be struck off from the school.
            </li>
            <li>
              Students must not attend school if they have any contagious
              diseases.
            </li>
            <li>
              The School I-Card duly filled must be pinned neatly on the
              shoulders of the child every day (for Pre-Primary).
            </li>
            <li>
              Students must take permission for leaving the class from the
              class/subject teacher, leaving the school from the Management,
              making any phone call from the Administrative Staff, and receiving
              visitors from the Management.
            </li>
          </ul>
        </Card>
        <Card title="Studies and Co-Curricular Activities">
          <ul>
            <li>
              Atharva School is an English/Gujarati Medium School. Students must
              converse in English/Gujarati during school hours.
            </li>
            <li>
              Students are required to attend all classes as scheduled in the
              respective class timetable. They must study with diligence and
              sincerity.
            </li>
            <li>
              Students should use textbooks and relevant literature for each
              subject as prescribed by the school. Guides to textbooks are not
              encouraged.
            </li>
            <li>
              Students must study systematically through regular class work and
              homework. Such discipline is essential for good academic
              performance. Homework for each subject must be done and submitted
              on the specified day. Homework should reflect the student's own
              effort and ideas.
            </li>
            <li>
              Term examination and Annual Examination will not be taken twice.
              In special cases, unit tests will be taken twice on furnishing a
              Medical Certificate.
            </li>
            <li>
              Students are required to preserve original copies of mark sheets
              of all examinations and testimonials issued by the School.
              Duplicates may be issued on payment of prescribed fees.
            </li>
            <li>
              Participation in co-curricular/extracurricular activities/games
              and sport is encouraged.
            </li>
            <li>
              The school will take utmost care of the students, but if any
              mishap occurs during activities (like Karate etc.) then the school
              will not be held responsible.
            </li>
          </ul>
        </Card>
        <Card title="General">
          <ul>
            <li>
              Students are strictly prohibited from bringing the following to
              the school: Obscene or vulgar literature, photographs etc. Money,
              jewellery, valuables, electronic games, pagers, cellular phones
              etc. Sticks, knives or any martial art equipment. Chewing gums,
              pan and pan masala, banned drugs, cigarettes and any unlawful
              items of consumption.
            </li>
            <li>
              Any notice, circular or report given by the school must be handed
              over by the students to his/her parents/guardians and returned to
              the school, where necessary, with the signature of the
              parent/guardian.
            </li>
            <li>
              Students must look after their own belongings. The School will not
              be responsible for any loss. If a student wants to get his/her
              forms/documents attested from the school office, then, he/she
              should submit it in the school office and may collect it on the
              third day from the office.
            </li>
          </ul>
        </Card>
        <Card title="Suspension/Dismissal/Termination">
          <ul>
            The School reserves the right to suspend, dismiss or terminate a
            student if he/she does not follow the student's code of conduct.
            Acts of indiscipline as listed below will be severely dealt with:
            <li>Regular late coming to school.</li>
            <li>Staying away from classes and morning assembly.</li>
            <li>Not adhering to dining room etiquettes.</li>
            <li>
              Misusing school property, scribbling on walls, furniture, bulletin
              boards, toilets walls etc.
            </li>
            <li>Mishandling classroom equipment.</li>
            <li>Copying or seeking help in the examination hall.</li>
            <li>Using vulgar and objectionable language.</li>
            <li>Possessing obscene or vulgar literature, photographs etc.</li>
            <li>Displaying violent behaviour, insubordination.</li>
            <li>Custodian of sticks, knives or any martial art equipment.</li>
            <li>Carrying mobile phones, electronic games etc.</li>
            <li>
              Chewing gum, pan and pan masala, banned drugs, cigarettes or any
              unlawful items of consumption.
            </li>
          </ul>
        </Card>
      </div>
      <ContactUs />
    </div>
  );
};

const Card = ({ title, children }) => (
  <div className="card">
    <h3 className="card-title">{title}</h3>
    <div className="card-content">{children}</div>
  </div>
);
