import React from "react";
import AboutSchool from "../../components/AboutSchool";
import AppFeatures from "../../components/AppFeatures";
import DirectorMessage from "../../components/DirectorMessage";
import Management from "../../components/Management";
import SchoolFacilities from "../../components/SchoolFacilities";
import { ContactUs } from "../ContactUs";
import "./Home.css";
import SliderImages from "../../components/SliderImages";

function Home() {
  return (
    <div id="Home" className="home-main-container">
      <SliderImages />
      <AboutSchool />
      <DirectorMessage />
      <AppFeatures />
      <SchoolFacilities />
      <Management />
      <ContactUs />
    </div>
  );
}

export default Home;
