import React, { useState } from "react";
import TopHeading from "../../components/TopHeading";
import "./SchoolInformationStyles.css";
import Images from "../../images";

const SchoolInfoSection = () => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className="school-info-container">
      <div className="school-info-images">
        <img
          className="imgStyle"
          decoding="async"
          width="580"
          height="530"
          src={Images.studentCollage}
          alt=""
          sizes="(max-width: 640px) 100vw, 640px"
        />
      </div>
      <div className="school-info">
        <p className="school-info-title">INFO</p>
        <h2>School Informations</h2>
        <div className="info-section">
          <div className="info-header" onClick={() => toggleSection("board")}>
            <span>Board</span>
            <span>{activeSection === "board" ? "-" : "+"}</span>
          </div>
          {activeSection === "board" && (
            <p className="info-content">
              Our school is affiliated with GSEB curriculum
            </p>
          )}
        </div>
        <div className="info-section">
          <div className="info-header" onClick={() => toggleSection("section")}>
            <span>Section</span>
            <span>{activeSection === "section" ? "-" : "+"}</span>
          </div>
          {activeSection === "section" && (
            <p className="info-content">
              Pre-Primary, Primary, Secondary and Higher Secondary Section
            </p>
          )}
        </div>
        <div className="info-section">
          <div className="info-header" onClick={() => toggleSection("medium")}>
            <span>Medium of Instruction</span>
            <span>{activeSection === "medium" ? "-" : "+"}</span>
          </div>
          {activeSection === "medium" && (
            <p className="info-content">English, Gujarati</p>
          )}
        </div>
        <div className="info-section">
          <div
            className="info-header"
            onClick={() => toggleSection("governedBy")}
          >
            <span>Managed By</span>
            <span>{activeSection === "governedBy" ? "-" : "+"}</span>
          </div>
          {activeSection === "governedBy" && (
            <p className="info-content">Mother Care Charitable Trust</p>
          )}
        </div>
        <div className="info-section">
          <div className="info-header" onClick={() => toggleSection("dining")}>
            <span>Dining Facilities</span>
            <span>{activeSection === "dining" ? "-" : "+"}</span>
          </div>
          {activeSection === "dining" && (
            <p className="info-content">Not Available</p>
          )}
        </div>
        <div className="info-section">
          <div className="info-header" onClick={() => toggleSection("motto")}>
            <span>Our Motto</span>
            <span>{activeSection === "motto" ? "-" : "+"}</span>
          </div>
          {activeSection === "motto" && (
            <p className="info-content">
              Success is a journey not a destination.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const SchoolInformation = () => {
  return (
    <div
      id="SchoolInformationScreen"
      className="school-information-screen-main-container"
    >
      <TopHeading heading="School Information" />
      <SchoolInfoSection />
    </div>
  );
};

export default SchoolInformation;
