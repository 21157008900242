import React, { useEffect, useState } from "react";
import TopHeading from "../../components/TopHeading";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../configs";
import "./PressAndMediaStyles.css";

const PhotoModal = ({
  photo,
  closePhotoModal,
  nextPhoto,
  prevPhoto,
  currentIndex,
  totalPhotos,
}) => {
  return (
    <div className="pm-modal-overlay" onClick={closePhotoModal}>
      <div className="pm-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pm-photo-container">
          <div className="pm-prev-button" onClick={prevPhoto}>
            <FaArrowLeft size={24} />
          </div>
          <img src={photo.photoUrl} alt={photo.photoName} />
          <div className="pm-next-button" onClick={nextPhoto}>
            <FaArrowRight size={24} />
          </div>
        </div>
        <p>
          {currentIndex + 1} / {totalPhotos}
        </p>
      </div>
    </div>
  );
};

const PressAndMediaSection = () => {
  const [album, setAlbum] = useState({});
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openPhotoModal = (photo, index) => {
    setSelectedPhoto(photo);
    setCurrentIndex(index);
  };

  const closePhotoModal = () => {
    setSelectedPhoto(null);
  };

  const nextPhoto = () => {
    const newIndex = (currentIndex + 1) % album.photos.length;
    setSelectedPhoto(album.photos[newIndex]);
    setCurrentIndex(newIndex);
  };

  const prevPhoto = () => {
    const newIndex =
      (currentIndex - 1 + album.photos.length) % album.photos.length;
    setSelectedPhoto(album.photos[newIndex]);
    setCurrentIndex(newIndex);
  };

  const fetchAlbums = async () => {
    await getDocs(collection(db, "Albums"))
      .then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        const albumIndex = newData.findIndex(
          (item) =>
            item.albumName?.toLowerCase?.()?.trim?.() === "pressandmedia" ||
            item.albumName?.toLowerCase?.()?.trim?.() === "press andmedia" ||
            item.albumName?.toLowerCase?.()?.trim?.() === "pressand media" ||
            item.albumName?.toLowerCase?.()?.trim?.() === "press and media"
        );
        console.log(newData, albumIndex);
        if (albumIndex !== -1) {
          const pressAndMediaAlbum = newData[albumIndex];
          setAlbum(pressAndMediaAlbum);
        } else {
          setAlbum({});
        }
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    fetchAlbums();
  }, []);

  return (
    <div className="pm-section">
      <div className="pm-photos-container">
        {album.photos ? (
          <>
            {album.photos.map((photo, index) => (
              <img
                key={photo.id}
                src={photo.photoUrl}
                alt={photo.photoName}
                onClick={() => openPhotoModal(photo, index)}
                className="pm-photo"
              />
            ))}
          </>
        ) : (
          <div className="pm-no-data-container">
            <p className="pm-no-data">No Data Added!</p>
          </div>
        )}
      </div>
      {selectedPhoto && (
        <PhotoModal
          photo={selectedPhoto}
          closePhotoModal={closePhotoModal}
          nextPhoto={nextPhoto}
          prevPhoto={prevPhoto}
          currentIndex={currentIndex}
          totalPhotos={album.photos.length}
        />
      )}
    </div>
  );
};

const PressAndMediaScreen = () => {
  return (
    <div id="PressAndMedia" className="pm-main-container">
      <TopHeading heading="Press And Media" />
      <PressAndMediaSection />
    </div>
  );
};

export default PressAndMediaScreen;
