import React, { useState } from "react";
import { GiGraduateCap } from "react-icons/gi";
import { GoBook } from "react-icons/go";
import { PiMedal } from "react-icons/pi";
import { FromTheDesk } from "../../components";
import TopHeading from "../../components/TopHeading";
import { ContactUs } from "../ContactUs";
import "./AboutUsScreenStyles.css";
import Images from "../../images";

const AboutUsSection = () => {
  return (
    <div className="about-section">
      <div className="aboutus-top">
        <div className="aboutus-content">
          <h2 className="aboutus-h2">About The Atharava Vidyalaya</h2>
          <p className="aboutus-p">
            School is recognised by G.S.E.B. The Certificates provided are
            globally valid and it has a better footage at National and
            International level. Atharava Vidyalaya is located at Chhani Road,
            Vadodara in a very peaceful area. School campus is accordance with
            the modern educational institutional requirements.
          </p>
          <p className="aboutus-p">
            "The school's ethos, is based upon respect of each individual and as
            reflected by demonstrate in positive relationships, good behaviour
            and attitudes in learning."
          </p>
          <p className="aboutus-p">
            We provide the atmosphere, where children get a good start to their
            education and due to the understanding they gain they make in
            personal, social and emotional development. They are confident, well
            rounded individuals who have a love of learning. The strongest
            feature of the school is its friendly inclusive and cohesive nature.
            Children are known and valued as individuals.
          </p>
          <p className="aboutus-p">
            We develop the thinking in children that they must have a clear
            moral understanding, developing their skills of empathy and learn to
            work and play together well.
          </p>
        </div>
        <div className="aboutus-images">
          <img
            className="imgStyle"
            decoding="async"
            width="580"
            height="530"
            src={Images.studentCollage}
            alt=""
            sizes="(max-width: 640px) 100vw, 640px"
          />
        </div>
      </div>
      <div className="aboutus-bottom">
        <p>
          "The education of child is a partnership between school and home. Time
          to time parental involvement in the activities is done, by parent's
          meetings and home learning. It makes the school highly inclusive in
          its philosophy and intent."
        </p>
        <p>
          In our school self-discipline is developed, we motivate the pupils, by
          theme related work, expressive artwork and creative work. Easily
          adopted rules are followed to enable the pupil to become a good
          citizen, so rather than instructed, pupils are consulted and guided
          here. We always follow the rules to maintain discipline in the school,
          not only in behaviour but also in day to day activities during school
          hours. For overall development, students are exposed to various
          curricular, co-curricular activities and sports, during school hours,
          under the guidance of trained teachers to understand their needs not
          only in academics but physical, mental and emotional developments
          also.
        </p>
      </div>
    </div>
  );
};

const CoreValuesSection = () => {
  const [selectedValue, setSelectedValue] = useState("vision");

  const renderCardwiseImage = (
    value = "vision",
    size = 40,
    color = "#ffffff"
  ) => {
    switch (value) {
      case "vision":
        return <PiMedal size={size} color={color} />;
      case "mission":
        return <GiGraduateCap size={size} color={color} />;
      case "faith":
        return <GoBook size={size} color={color} />;
      default:
        return <PiMedal size={size} color={color} />;
    }
  };

  const renderCardwiseDescription = () => {
    switch (selectedValue) {
      case "vision":
        return `Believe, Achieve Succeed," We believe in our children, encouraging them to achieve at all levels and succeed in life. Our education aims to develop the whole persion so that each can maximise potential and fruitful their unique role in creation`;
      case "mission":
        return `We believe that all children are important and capable of many forms of development. Our mission is to inspire all our young people to achieve their full potential in a happy, secure, creative learning environment where every individual is valued and respected and has sense of belongings. At the same time we aim to make our young people aware of the nature of society, their responsibilities towards people and an environment as citizens of the future.`;
      case "faith":
        return `It is our faith that each pupil will come out with the total potentialities, he or she has. As toddlers in a safe nurturing and joyful setting, we guide the children from the age of 3 years with remarkable activities that stimulate cognitive growth and support the development of emotional and social skills.`;
      default:
        return `Believe, Achieve Succeed," We believe in our children, encouraging them to achieve at all levels and succeed in life. Our education aims to develop the whole persion so that each can maximise potential and fruitful their unique role in creation`;
    }
  };

  return (
    <div className="core-values-section">
      <div className="vision-tabs">
        <div
          className={`cv-tab ${selectedValue === "vision" ? "active" : ""}`}
          onClick={() => setSelectedValue("vision")}
        >
          {renderCardwiseImage(
            "vision",
            40,
            selectedValue === "vision" ? "#000000" : "#ffffff"
          )}
          <span>Our Vision</span>
        </div>
        <div
          className={`cv-tab ${selectedValue === "faith" ? "active" : ""}`}
          onClick={() => setSelectedValue("faith")}
        >
          {renderCardwiseImage(
            "faith",
            40,
            selectedValue === "faith" ? "#000000" : "#ffffff"
          )}
          <span>Our Faith</span>
        </div>
        <div
          className={`cv-tab ${selectedValue === "mission" ? "active" : ""}`}
          onClick={() => setSelectedValue("mission")}
        >
          {renderCardwiseImage(
            "mission",
            40,
            selectedValue === "mission" ? "#000000" : "#ffffff"
          )}
          <span>Our Mission</span>
        </div>
      </div>
      <div className="vision-content">
        <div className="vision-image">
          {renderCardwiseImage(selectedValue, 80, "#ee7d27")}
        </div>
        <div className="vision-text">
          <h2>Our {selectedValue}</h2>
          <p>{renderCardwiseDescription()}</p>
        </div>
      </div>
    </div>
  );
};

const SchoolManagementSection = () => {
  return (
    <div className="sms-section">
      <div className="sms-image-container">
        <img
          loading="lazy"
          decoding="async"
          src={Images.director}
          className="attachment-full size-full wp-image-12261"
          alt=""
          sizes="(max-width: 547px) 100vw, 547px"
        />
      </div>
      <div className="sms-content">
        <h2>School Management</h2>
        <p>
          Education must enhance the human beings, we feel. There is a friendly,
          caring atmosphere throughout the school where pupils feel supported in
          their faith and their views are valued. We here nurtures......
        </p>
        <p>
          Work hard and learn to climb the ladder of success. We up hold the
          government's policy of right to education and for us, the parents,
          school and child form a triangle. We Shape your dream to make your
          ward a dynamic personality. We here not only impart education but it
          is an overall development of the pupils. Mother care charitable trust
          always provide the best and quality education to the society. Under
          the supervision of Smt. Vandana H. Virk, develop such an educational
          system that the students can acquire self-confidence by completing
          whole curricular within the stipulated span of time with proper
          planning
        </p>
        <p>
          The comprehensive and continuous Evaluation and co-curricular
          activities shape them to become global citizen. Whole school building
          is equipped with study halls, laboratories, Exam hall, AV. hall,
          Library, Area for indoor and outdoor games. The whole school campus is
          monitoring by CCTV at each classroom and corridors.
        </p>
      </div>
    </div>
  );
};

const OurInfrastructureSection = () => {
  return (
    <div className="oif-section">
      <div className="oif-content">
        <h2>Our Infrastructure</h2>
        <p>
          Attentive early care enriches the developing brain, fosters multiple
          intelligence, good studies and a love of learning. To keep in mind
          this presently we are having classes from NURSERY to Std.: 12th
          Science/Commerce (English/Gujarati Medium). A separate floor for K.G.
          Section equipped with all the teaching instruments, educational toys
          and play area. In Primary section, we have well organized examination
          system at board level and national levels.
        </p>
      </div>
      <div className="oif-image-container">
        <img
          loading="lazy"
          decoding="async"
          src={Images.slider1}
          alt=""
          sizes="(max-width: 547px) 100vw, 547px"
        />
      </div>
    </div>
  );
};

const EmphasizeOn4CSection = () => {
  return (
    <div className="ep4c-section">
      <div className="ep4c-centred-div">
        <h2>The main emphasize is on 4C's</h2>
        <h3>Curiosity - Celebration - Creativity - Care</h3>
        {/* <div className="ep4c-image-container">
          <img
            loading="lazy"
            decoding="async"
            src="https://brightschoolvadodara.in/wp-content/uploads/2022/03/Ms.-Uma.jpg"
            className="attachment-full size-full wp-image-12261"
            alt=""
            srcSet="https://brightschoolvadodara.in/wp-content/uploads/2022/03/Ms.-Uma.jpg 547w, https://brightschoolvadodara.in/wp-content/uploads/2022/03/Ms.-Uma-300x263.jpg 300w"
            sizes="(max-width: 547px) 100vw, 547px"
          />
          <img
            loading="lazy"
            decoding="async"
            src="https://brightschoolvadodara.in/wp-content/uploads/2022/03/Ms.-Uma.jpg"
            className="attachment-full size-full wp-image-12261"
            alt=""
            srcSet="https://brightschoolvadodara.in/wp-content/uploads/2022/03/Ms.-Uma.jpg 547w, https://brightschoolvadodara.in/wp-content/uploads/2022/03/Ms.-Uma-300x263.jpg 300w"
            sizes="(max-width: 547px) 100vw, 547px"
          />
        </div> */}
        <div className="ep4c-content">
          <ul>
            <li>
              Curriculum is right from the handwriting to the spelling and
              reading etc.
            </li>
            <li>
              The formative years of schooling are critical, hence stress is
              given on basic skills.
            </li>
            <li>
              The development of positive attitude and positive actions are also
              included in the curriculum.
            </li>
            <li>
              The stress of examination is done away within the formative years
              of child's learning. The evaluation is a continuous and
              comprehensive evolution spread over the entire part of academic
              session and it is done in an informal manner.
            </li>
            <li>
              It is the depth of good leadership across the school with staff
              teamwork, come up with high expectations and commitment to the
              pupil at all levels.
            </li>
            <li>
              We have a highly qualified and trained staff with optimal
              teacher-student ratio of not more than 1:5 enable the teachers to
              give personalised attention to each and every students.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const AboutUsScreen = () => {
  return (
    <div id="AboutUsScreen" className="about-us-screen-main-container">
      <TopHeading heading="About Us" />
      <AboutUsSection />
      <FromTheDesk name="Mrs. Vandana Virk" designation="Director" />
      <CoreValuesSection />
      <SchoolManagementSection />
      <OurInfrastructureSection />
      <EmphasizeOn4CSection />
      <ContactUs />
    </div>
  );
};
