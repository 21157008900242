import React from "react";
import "./FooterStyles.css";
import Images from "../../images";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";
import { ImLocation2 } from "react-icons/im";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { MdOutlineAccessTimeFilled } from "react-icons/md";

export const Footer = ({ setActiveLink }) => {
  const handleFooterLinkClick = (link) => {
    setActiveLink(link);
    window.scrollTo(0, 0);
  };

  const handleSocialIconClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="footer-main-container">
      <div className="footer-container">
        <div className="footer-description">
          <div className="footer-logo">
            <img src={Images.logo} alt="Bright School - GSEB Unit" />
          </div>
          <p>
            Atharava Vidyalaya, is a state-of-the-art English as well as
            Gujarati medium school equipped with excellent and modern education
            and sporting infrastructure. It is administered by the “Mother Care
            Charitable Trust” and offers the GSEB Curriculum.
          </p>
          <div className="footer-social-icons">
            <div
              onClick={() =>
                handleSocialIconClick(
                  "https://www.facebook.com/p/Atharva-vidyalaya-100083112703140/"
                )
              }
            >
              <FaFacebook />
            </div>
            <div
              onClick={() =>
                handleSocialIconClick(
                  "https://www.instagram.com/atharavavidyalaya/"
                )
              }
            >
              <FaInstagram />
            </div>
            <div
              onClick={() =>
                handleSocialIconClick(
                  "https://www.youtube.com/@AtharavaVidyalaya"
                )
              }
            >
              <TfiYoutube />
            </div>
          </div>
        </div>
        <div className="footer-address">
          <h3>Address</h3>
          <p>
            <ImLocation2 className="footer-logo-style" size={20} />
            <a
              href="https://maps.app.goo.gl/89EwxhgvqH9Y8qih8"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              Atharava Vidyalaya, Guru Gobindsinhji Marg, Chhani, Vadodara,
              Gujarat 391740
            </a>
          </p>
          <p>
            <FaPhoneAlt className="footer-logo-style" size={20} />
            <a href="tel:+919638916655" className="footer-link">
              (+91) 9638916655
            </a>
          </p>
          <p>
            <MdEmail className="footer-logo-style" size={20} />
            <a
              href="mailto:atharava_vidyalaya_chhani@yahoo.com"
              className="footer-link"
            >
              atharava_vidyalaya_chhani@yahoo.com
            </a>
          </p>
          <p className="nohover-style">
            <MdOutlineAccessTimeFilled
              className="footer-logo-style"
              size={20}
            />
            07:15AM to 5:30PM
          </p>
        </div>
        <div className="footer-quick-links">
          <h3>Quick Links</h3>
          <a
            href="#admissions"
            onClick={() => handleFooterLinkClick("admission")}
          >
            <GoDotFill className="footer-logo-style" size={15} />
            Admissions
          </a>
          <a
            href="#press"
            onClick={() => handleFooterLinkClick("pressAndMedia")}
          >
            <GoDotFill className="footer-logo-style" size={15} />
            Press & Media
          </a>
          <a href="#gallery" onClick={() => handleFooterLinkClick("gallery")}>
            <GoDotFill className="footer-logo-style" size={15} />
            Gallery
          </a>
          <a
            href="#contactus"
            onClick={() => handleFooterLinkClick("contactus")}
          >
            <GoDotFill className="footer-logo-style" size={15} />
            Contact Us
          </a>
        </div>
        <div className="footer-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7379.851638247755!2d73.16358304265931!3d22.3564296685848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc92f9806c16b%3A0xfbf423cc6a26b185!2sAtharva%20Vidyalaya!5e0!3m2!1sen!2sin!4v1720524627075!5m2!1sen!2sin"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            title="Atharava Vidyalaya"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <footer>
        <p>Copyright 2024 Atharava Vidyalaya. All Rights Reserved.</p>
      </footer>
    </div>
  );
};
