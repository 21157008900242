import React from "react";
import "./Header.css";
import { IoMailOutline } from "react-icons/io5";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";

function Header() {
  const handleCardClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="h-header-container">
      <div className="h-headerInfos">
        <div
          className="h-gmail"
          onClick={() =>
            handleCardClick("mailto:atharava_vidyalaya_chhani@yahoo.com")
          }
        >
          <IoMailOutline className="p-header-logo-style" size={15} />
          atharava_vidyalaya_chhani@yahoo.com
        </div>
        <div>|</div>
        <div
          className="h-mobile"
          onClick={() => handleCardClick("tel:+919638916655")}
        >
          <MdOutlinePhoneInTalk className="p-header-logo-style" size={15} />
          (+91) 0265 276 0009
        </div>
      </div>
      <div className="h-containerright">
        <a
          rel="noreferrer"
          href="https://www.facebook.com/p/Atharva-vidyalaya-100083112703140/"
          target="_blank"
        >
          <FaFacebook className="footer-logo-style" size={15} />
        </a>
        <a
          rel="noreferrer"
          href="https://www.instagram.com/atharavavidyalaya/"
          target="_blank"
        >
          <FaInstagram className="footer-logo-style" size={15} />
        </a>
        <a
          rel="noreferrer"
          href="https://www.youtube.com/@AtharavaVidyalaya"
          target="_blank"
        >
          <TfiYoutube className="footer-logo-style" size={15} />
        </a>
      </div>
    </div>
  );
}

export default Header;
