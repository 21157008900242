import React from "react";
import {
  FaCalendarAlt,
  FaChartBar,
  FaListUl,
  FaUserCheck,
} from "react-icons/fa";
import TopHeading from "../../components/TopHeading";
import "./ExaminationStyles.css";

const TestTimeTable = () => {
  return (
    <>
      <h2 className="examination-subtitle">
        <FaCalendarAlt /> Test Time Table
      </h2>
      <div className="table-responsive">
        <table className="examination-table">
          <thead>
            <tr>
              <th>Level</th>
              <th>Day 1</th>
              <th>Day 2</th>
              <th>Day 3</th>
              <th>Day 4</th>
              <th>Day 5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nur. / Jr. Kg.</td>
              <td>Project Talk, Poetry Reading, Picture Reading and writing</td>
              <td>Language and G.K.</td>
              <td>Maths and Drawing</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Sr. Kg.</td>
              <td>G.K.</td>
              <td>Project Talk, Poetry and Picture Reading</td>
              <td>Language (Dictation Writing Assignment and Copy Writing)</td>
              <td>Maths and Drawing</td>
              <td>Living Science/Hindi</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const Evaluation = () => {
  return (
    <>
      <h2 className="examination-subtitle">
        <FaListUl /> Evaluation
      </h2>
      <table className="examination-table">
        <thead>
          <tr>
            <th>Class</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Std. 1 and 2</td>
            <td>
              8 Monthly Tests (8 x 10 = 80), Two Terms Oral's (10 + 10 = 20)
            </td>
          </tr>
          <tr>
            <td>Std. 3 to 5</td>
            <td>
              1st Sem: SA 1 = 40, ORAL = 10, Total = 50 | 2nd Sem: SA 2 = 40,
              ORAL = 10, Total = 50
            </td>
          </tr>
          <tr>
            <td>Std. 6 to 8</td>
            <td>
              1st Sem: SA 1 = 80, ORAL/PROJECT = 20, Total = 100 | 2nd Sem: SA 2
              = 80, ORAL/PROJECT = 20, Total = 100
            </td>
          </tr>
          <tr>
            <td>Std. 9th & 11th Com./Sci.</td>
            <td>
              1st Term Exam = 50 Marks, 2nd Term Exam = 50 Marks, 3rd Term Exam
              = 80 Marks, Internal = 20 Marks, Total = 200 Marks
            </td>
          </tr>
          <tr>
            <td>Std. 10th</td>
            <td>
              1st Term Exam = 100 Marks, Prelim Exam = 100 Marks, SSC Board Exam
              = 80 Marks, Internal = 20 Marks
            </td>
          </tr>
          <tr>
            <td>Std. 12th Com./Sci.</td>
            <td>
              1st Term Exam = 100 Marks, Prelim Exam = 100 Marks, SSC/HSC Board
              Exam = 100 Marks
            </td>
          </tr>
        </tbody>
      </table>
      <div className="examination-points-container">
        <ul className="examination-points">
          <li>
            <b>Std. 1st and 2nd:</b> 8th Monthly Test (Annual Exam) will be of
            50 marks and it will be converted into 10 Marks.
          </li>
          <li>
            <b> Std. 3rd to 8th:</b> There will be two semester examinations
            conducted in the month of September / October and April.
          </li>
          <li>
            <b> Std. 9th & 11th Com./Sci.:</b> There will be three examinations
            including the final *Internal assessment and class tests will also
            be conducted.* The 3 Terminal examinations will be conducted in the
            month of October, January and April.
          </li>
          <li>
            <b>Std. 10th & 12th Com./Sci.:</b> There will be two examinations
            100 marks of each.
          </li>
        </ul>
      </div>
    </>
  );
};

const Attendance = () => {
  return (
    <>
      <h2 className="examination-subtitle">
        <FaUserCheck /> Attendance
      </h2>
      <p className="examination-text">
        A minimum of 80% attendance is mandatory for promotion to the next
        higher class at all 4 levels.
      </p>
    </>
  );
};

const EvaluationSystem = () => {
  return (
    <>
      <h2 className="examination-subtitle">
        <FaChartBar /> Evaluation System
      </h2>
      <table className="examination-table">
        <thead>
          <tr>
            <th>Grade</th>
            <th>Marks Range</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A1</td>
            <td>91 - 100%</td>
          </tr>
          <tr>
            <td>A2</td>
            <td>81 - 90%</td>
          </tr>
          <tr>
            <td>B1</td>
            <td>71 - 80%</td>
          </tr>
          <tr>
            <td>B2</td>
            <td>61 - 70%</td>
          </tr>
          <tr>
            <td>C1</td>
            <td>51 - 60%</td>
          </tr>
          <tr>
            <td>C2</td>
            <td>41 - 50%</td>
          </tr>
          <tr>
            <td>D</td>
            <td>33 - 40%</td>
          </tr>
          <tr>
            <td>E1/E2</td>
            <td>Below 33%</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const ExaminationSection = () => {
  return (
    <div className="examination-container">
      <div className="examination-section">
        <TestTimeTable />
      </div>
      <div className="examination-section">
        <Evaluation />
      </div>
      <div className="examination-section">
        <EvaluationSystem />
      </div>
      <div className="examination-section">
        <Attendance />
      </div>
    </div>
  );
};

const Examination = () => {
  return (
    <div id="Examination" className="examination-main-container">
      <TopHeading heading="Examination" />
      <ExaminationSection />
    </div>
  );
};

export default Examination;
