import React from "react";
import "./ManagementStyles.css";
import Images from "../../images";

const Management = () => {
  return (
    <div className="management-container">
      <h2 className="management-section-title">Our</h2>
      <h3 className="management-sub-title">Management Personnel</h3>
      <div className="management-profile-container">
        <div className="management-profile-card">
          <img src={Images.managingTrustee} alt="Mr. Harsih B. Virk" />
          <div className="management-profile-info">
            <h4>Mr. Harsih B. Virk (Managing Trustee)</h4>
          </div>
        </div>
        <div className="management-profile-card">
          <img src={Images.trustee} alt="Mr. Harjivan Parbadia" />
          <div className="management-profile-info">
            <h4>Mr. Harjivanbhai Parbadia (Trustee)</h4>
          </div>
        </div>
        <div className="management-profile-card">
          <img src={Images.director} alt="Mrs Vandana Virk" />
          <div className="management-profile-info">
            <h4>Mrs. Vandana Virk (Director/Principal)</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Management;
