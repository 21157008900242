import React from "react";
import "./AboutSchoolStyles.css";
import Images from "./../../images";

export const AboutSchool = () => {
  return (
    <section id="AboutUs" className="about-container">
      <div className="inner-container">
        <div className="img_container">
          <div className="image_inside_container">
            <img
              className="imgStyle"
              decoding="async"
              width="580"
              height="530"
              src={Images.studentCollage}
              alt=""
              sizes="(max-width: 640px) 100vw, 640px"
            />
          </div>
        </div>
        <div className="textContainer">
          <h2 className="textStyle">Welcome To Atharava Vidyalaya</h2>
          <div className="description">
            <p className="descriptionStyle">
              Atharava Vidyalaya, is a state-of-the-art English as well as
              Gujarati medium school equipped with excellent and modern
              education and sporting infrastructure. It is administered by the
              “Mother Care Charitable Trust” and offers the GSEB Curriculum.
            </p>
            <p className="descriptionStyle">
              Ours is a new-age, co-educational school with modern academic and
              co-curricular facilities. Life at Atharava is vibrant,
              exhilarating and dedicated to the joy of learning. We are aware
              that learning is most rewarding in a joy of learning in a
              community that is lively, cooperative, critical and fundamentally
              curious. To be such a community of students, teachers,
              administrators and parents is our consistent endeavour, education
              system has also evolved to stand up to these challenges.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
