import React, { useEffect, useState } from "react";
import TopHeading from "../../components/TopHeading";
import "./GalleryStyles.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { db } from "./../../configs";
import { collection, getDocs } from "firebase/firestore";

const PhotoModal = ({
  photo,
  closePhotoModal,
  nextPhoto,
  prevPhoto,
  currentIndex,
  totalPhotos,
}) => {
  return (
    <div className="modal-overlay" onClick={closePhotoModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="photo-container">
          <div className="prev-button" onClick={prevPhoto}>
            <FaArrowLeft size={24} />
          </div>
          <img src={photo.photoUrl} alt={photo.photoName} />
          <div className="next-button" onClick={nextPhoto}>
            <FaArrowRight size={24} />
          </div>
        </div>
        <p>
          {currentIndex + 1} / {totalPhotos}
        </p>
      </div>
    </div>
  );
};

const GallerySection = () => {
  const tempAlbums = [
    {
      id: 1,
      albumName: "Annual Function",
      photos: [
        {
          id: 1,
          photoUrl:
            "https://firebasestorage.googleapis.com/v0/b/atharava-vidyalaya.appspot.com/o/gallery%2Falbums%2FAnnual%20Function%2F1000110285.jpg?alt=media&token=d4b37a13-23f4-4ef2-9b02-73ee6e2052fa",
          photName: "Photo 1",
        },
        {
          id: 2,
          photoUrl:
            "https://firebasestorage.googleapis.com/v0/b/atharava-vidyalaya.appspot.com/o/gallery%2Falbums%2FAnnual%20Function%2F1000110291.jpg?alt=media&token=a79458e7-c478-46ad-bdb9-4625708e39d9",
          photName: "Photo 2",
        },
      ],
    },
    {
      id: 2,
      albumName: "Celebrations",
      photos: [
        {
          id: 3,
          photoUrl:
            "https://firebasestorage.googleapis.com/v0/b/atharava-vidyalaya.appspot.com/o/gallery%2Falbums%2FAnnual%20Function%2F1000110289.jpg?alt=media&token=abcd88ec-94c8-4f55-86d6-82b10718aff8",
          photName: "Photo 3",
        },
        {
          id: 4,
          photoUrl:
            "https://firebasestorage.googleapis.com/v0/b/atharava-vidyalaya.appspot.com/o/gallery%2Falbums%2FAnnual%20Function%2F1000110283.jpg?alt=media&token=f1dae2b2-2d0c-41c1-922b-d4404753995c",
          photName: "Photo 4",
        },
      ],
    },
  ];
  const [albums, setAlbums] = useState(tempAlbums ?? []);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openAlbum = (album) => {
    setSelectedAlbum(album);
    setCurrentIndex(0);
  };

  const openPhotoModal = (photo, index) => {
    setSelectedPhoto(photo);
    setCurrentIndex(index);
  };

  const closePhotoModal = () => {
    setSelectedPhoto(null);
  };

  const nextPhoto = () => {
    const newIndex = (currentIndex + 1) % selectedAlbum.photos.length;
    setSelectedPhoto(selectedAlbum.photos[newIndex]);
    setCurrentIndex(newIndex);
  };

  const prevPhoto = () => {
    const newIndex =
      (currentIndex - 1 + selectedAlbum.photos.length) %
      selectedAlbum.photos.length;
    setSelectedPhoto(selectedAlbum.photos[newIndex]);
    setCurrentIndex(newIndex);
  };

  const fetchAlbums = async () => {
    await getDocs(collection(db, "Albums"))
      .then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        setAlbums(newData);
        console.log(newData);
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    fetchAlbums();
  }, []);

  return (
    <div className="gallery">
      {!selectedAlbum && (
        <div className="albums-container">
          {albums.map((album) => (
            <div
              key={album.id}
              className="album"
              onClick={() => openAlbum(album)}
            >
              <img src={album.photos[0].photoUrl} alt={album.albumName} />
              <div className="album-hover">
                <h3>{album.albumName}</h3>
              </div>
            </div>
          ))}
        </div>
      )}
      {selectedAlbum && (
        <>
          <h3
            className="photo-container-back"
            onClick={() => setSelectedAlbum(null)}
          >
            &lt; Back To Albums
          </h3>
          <div className="photos-container">
            {selectedAlbum.photos.map((photo, index) => (
              <img
                key={photo.id}
                src={photo.photoUrl}
                alt={photo.photoName}
                onClick={() => openPhotoModal(photo, index)}
                className="photo"
              />
            ))}
          </div>
        </>
      )}
      {selectedPhoto && (
        <PhotoModal
          photo={selectedPhoto}
          closePhotoModal={closePhotoModal}
          nextPhoto={nextPhoto}
          prevPhoto={prevPhoto}
          currentIndex={currentIndex}
          totalPhotos={selectedAlbum.photos.length}
        />
      )}
    </div>
  );
};

const Gallery = () => {
  return (
    <div id="Gallery" className="gallery-main-container">
      <TopHeading heading="Gallery" />
      <GallerySection />
    </div>
  );
};

export default Gallery;
