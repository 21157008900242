import React, { useEffect, useState } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { FaBars, FaCaretDown, FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import Images from "../../images";
import "./Navbar.css";

function Navbar({ activeLink, setActiveLink }) {
  const [dropdownOpen, setDropdownOpen] = useState({
    extra: false,
    studentsCorner: false,
    more: false,
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isStudentCornerOpen, setStudentCornerOpen] = useState(false);
  const [isMoreOpen, setMoreOpen] = useState(false);

  const toggleStudentCorner = () => {
    setStudentCornerOpen(!isStudentCornerOpen);
  };

  const toggleMore = () => {
    setMoreOpen(!isMoreOpen);
  };

  const handleDropdownToggle = (section) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setDropdownOpen({ extra: false, studentsCorner: false, more: false });
    window.scrollTo(0, 0);
    if (isDrawerOpen) setIsDrawerOpen(false); // Close drawer on link click
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    if (activeLink !== "home") setActiveLink("home");
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".menu-area");
      const header = document.querySelector(".h-header-container");
      const yHeight = navbar.clientHeight + header.clientHeight;

      if (window.scrollY > yHeight) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="menu-area">
        <div className="container-fluid">
          <div className="row-table">
            <div className="col-cell extra">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className={`headerText ${
                    activeLink === "extra" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleDropdownToggle("extra")}
                  onMouseLeave={() => handleDropdownToggle("extra")}
                >
                  <CgMenuGridR className="p-logo-style" size={18} />
                  EXTRA
                </span>
              </div>
              {dropdownOpen.extra && (
                <div
                  className="dropdown"
                  onMouseEnter={() => handleDropdownToggle("extra")}
                  onMouseLeave={() => handleDropdownToggle("extra")}
                >
                  <div
                    onClick={() =>
                      window.open(
                        "https://drive.google.com/file/d/1YXQIY2sLQbRd48ANqNXFvt-s4yARZCqI/view?usp=drive_link",
                        "_blank"
                      )
                    }
                  >
                    Admission Form
                  </div>
                  <div onClick={() => handleLinkClick("gallery")}>Gallery</div>
                </div>
              )}
            </div>
            <div className="col-cell links">
              <a
                href="#Home"
                className={`headerText ${
                  activeLink === "home" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("home")}
              >
                HOME
              </a>
              <a
                href="#AboutUs"
                className={`headerText ${
                  activeLink === "aboutusscreen" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("aboutusscreen")}
              >
                ABOUT US
              </a>
              <a
                href="#schoolInformation"
                className={`headerText ${
                  activeLink === "schoolInformation" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("schoolInformation")}
              >
                SCHOOL INFORMATION
              </a>
            </div>
            <div className="col-cell logo" onClick={handleLogoClick}>
              <img
                className="image-style"
                src={Images.logo}
                alt="School Logo"
              />
            </div>
            <div className="col-cell links">
              <a
                href="#students-corner"
                className={`headerText ${
                  activeLink === "students-corner" ? "active" : ""
                }`}
                onMouseEnter={() => handleDropdownToggle("studentsCorner")}
                onMouseLeave={() => handleDropdownToggle("studentsCorner")}
              >
                STUDENT'S CORNER
                <FaCaretDown className="p-logo-style" size={18} />
              </a>
              {dropdownOpen.studentsCorner && (
                <div
                  className="dropdown"
                  onMouseEnter={() => handleDropdownToggle("studentsCorner")}
                  onMouseLeave={() => handleDropdownToggle("studentsCorner")}
                >
                  <div onClick={() => handleLinkClick("codeOfConduct")}>
                    Code Of Conduct
                  </div>
                  <div onClick={() => handleLinkClick("examination")}>
                    Examination
                  </div>
                  <div onClick={() => handleLinkClick("miscellaneous")}>
                    Miscellaneous
                  </div>
                </div>
              )}
              <a
                href="#admissions"
                className={`headerText ${
                  activeLink === "admission" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("admission")}
              >
                ADMISSIONS
              </a>
              <a
                href="#more"
                className={`headerText ${
                  activeLink === "more" ? "active" : ""
                }`}
                onMouseEnter={() => handleDropdownToggle("more")}
                onMouseLeave={() => handleDropdownToggle("more")}
              >
                MORE
                <FaCaretDown className="p-logo-style" size={18} />
              </a>
              {dropdownOpen.more && (
                <div
                  className="dropdown"
                  style={{ left: "45%" }}
                  onMouseEnter={() => handleDropdownToggle("more")}
                  onMouseLeave={() => handleDropdownToggle("more")}
                >
                  <div onClick={() => handleLinkClick("pressAndMedia")}>
                    Press & Media
                  </div>
                  <div onClick={() => handleLinkClick("contactus")}>
                    Contact Us
                  </div>
                </div>
              )}
            </div>
            <div className="col-cell menu-container">
              <div onClick={handleDrawerToggle} className="menu-button">
                <FaBars />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDrawerOpen && (
        <div className={`drawer ${isDrawerOpen ? "open" : ""}`}>
          <div onClick={handleDrawerToggle} className="close-button">
            <FaTimes />
          </div>
          <ul className="drawer-list">
            <li onClick={() => handleLinkClick("home")}>Home</li>
            <li onClick={() => handleLinkClick("aboutusscreen")}>About Us</li>
            <li onClick={() => handleLinkClick("schoolInformation")}>
              School Information
            </li>
            <li>
              <div className="drawer-item" onClick={toggleStudentCorner}>
                Student's Corner
                <div className="drawer-item-toggle">
                  {isStudentCornerOpen ? <FaMinus /> : <FaPlus />}
                </div>
              </div>
              {isStudentCornerOpen && (
                <ul className="nested-list">
                  <li onClick={() => handleLinkClick("codeOfConduct")}>
                    Code of Conduct
                  </li>
                  <li onClick={() => handleLinkClick("examination")}>
                    Examination
                  </li>
                  <li onClick={() => handleLinkClick("miscellaneous")}>
                    Miscellaneous
                  </li>
                </ul>
              )}
            </li>
            <li onClick={() => handleLinkClick("admission")}>Admissions</li>
            <li>
              <div className="drawer-item" onClick={toggleMore}>
                More
                <div className="drawer-item-toggle">
                  {isMoreOpen ? <FaMinus /> : <FaPlus />}
                </div>
              </div>
              {isMoreOpen && (
                <ul className="nested-list">
                  <li onClick={() => handleLinkClick("pressAndMedia")}>
                    Press and Media
                  </li>
                  <li onClick={() => handleLinkClick("contactus")}>
                    Contact Us
                  </li>
                </ul>
              )}
            </li>
            <li onClick={() => handleLinkClick("gallery")}>Gallery</li>
          </ul>
        </div>
      )}
    </>
  );
}

export default Navbar;
