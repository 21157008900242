import React from "react";
import { FromTheDesk } from "../../components";
import DirectorMessage from "../../components/DirectorMessage";
import TopHeading from "../../components/TopHeading";
import { ContactUs } from "../ContactUs";
import "./AcademicsStyles.css";

export const Academics = () => {
  return (
    <div id="academics" className="academics-screen-main-container">
      <TopHeading heading="Academics" />
      <FromTheDesk name="Mrs. Vandana Virk" designation="Director" />
      <DirectorMessage />
      <ContactUs />
    </div>
  );
};
