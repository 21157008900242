import React from "react";
import "./AppFeaturesStyles.css";
import { RiPresentationFill } from "react-icons/ri";
import { TbTimelineEventFilled } from "react-icons/tb";
import { MdPayments } from "react-icons/md";
import { RiGalleryFill } from "react-icons/ri";
import { IoReceipt } from "react-icons/io5";
import { ImBooks } from "react-icons/im";
import Images from "../../images";

const handlePlayStoreClick = () => {
  window.open(
    "https://play.google.com/store/apps/details?id=com.moddsoftdevelopers.schoolapp",
    "_blank"
  );
};

export const AppFeatures = () => {
  return (
    <div className="section-padding">
      <div className="section-header text-center">
        <h3>App's Features</h3>
        <span className="line"></span>
        <p>
          Our School App offers a comprehensive platform with features such as
          attendance tracking, important announcements, activity timelines,
          homework management, fee payments, and a multimedia gallery. This app
          enhances communication and engagement among students, parents, and
          teachers, ensuring a seamless and efficient school experience.
        </p>
      </div>
      <div className="section-content text-center">
        <div className="feature-row">
          <div className="feature-left-container">
            <div className="media single-feature">
              <div className="media-body text-right media-right-margin">
                <h5>Attendance</h5>
                <p>
                  Mark and track student attendance with real-time updates for
                  parents and students.
                </p>
              </div>
              <div className="media-right icon-border">
                <RiPresentationFill
                  aria-hidden="true"
                  className="feature-img"
                />
              </div>
            </div>
            <div className="media single-feature">
              <div className="media-body text-right media-right-margin">
                <h5>Circular</h5>
                <p>
                  Get important school announcements and updates with
                  notification alerts for parents and students.
                </p>
              </div>
              <div className="media-right icon-border">
                <IoReceipt aria-hidden="true" className="feature-img" />
              </div>
            </div>
            <div className="media single-feature">
              <div className="media-body text-right media-right-margin">
                <h5>Homework</h5>
                <p>
                  Track homework, enable online submissions, accessible by
                  students and parents.
                </p>
              </div>
              <div className="media-right icon-border">
                <ImBooks aria-hidden="true" className="feature-img" />
              </div>
            </div>
          </div>
          <div className="feature-mobile" onClick={handlePlayStoreClick}>
            <img src={Images.mockup} className="img-fluid" alt="Feature" />
            <img
              src={Images.playstore}
              className="playstore-img-fluid"
              alt="Playstore"
            />
          </div>
          <div className="feature-right-container">
            <div className="media single-feature">
              <div className="media-left icon-border media-right-margin">
                <TbTimelineEventFilled
                  aria-hidden="true"
                  className="feature-img"
                />
              </div>
              <div className="media-body text-left">
                <h5>Timeline</h5>
                <p>
                  A chronological feed of attendance, circular, homework to
                  foster engagement.
                </p>
              </div>
            </div>
            <div className="media single-feature">
              <div className="media-left icon-border media-right-margin">
                <MdPayments aria-hidden="true" className="feature-img" />
              </div>
              <div className="media-body text-left">
                <h5>Fees</h5>
                <p>
                  Manage fee payments digitally with online payment options and
                  notifications for due dates.
                </p>
              </div>
            </div>
            <div className="media single-feature">
              <div className="media-left icon-border media-right-margin">
                <RiGalleryFill aria-hidden="true" className="feature-img" />
              </div>
              <div className="media-body text-left">
                <h5>Photo and Video Gallery</h5>
                <p>
                  A repository for school event photos and videos, organized
                  into albums for browsing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
