import React from "react";
import "./DirectorMessageStyles.css";
import Images from "../../images";

export const DirectorMessage = () => {
  return (
    <div className="director-msg-container">
      <div className="director-msg-section">
        <h2 className="director-msg-title">Message</h2>
        <h1 className="director-title-title">Director’s Message</h1>
        <p className="director-msg-content">
          At Atharava Vidyalaya, we are always guided by our foundational
          philosophy of providing children happy and rewarding learning
          experiences, while continuously incorporating the distinct features of
          a school of the future, so we foster in them the 21st century skills:
          critical thinking, creativity, collaboration and communication.
        </p>
        <p className="director-msg-name">
          <i>Shrimati Vandana Virk</i>
        </p>
        <p className="director-title">Director/Principal, Atharava Vidyalaya</p>
      </div>
      <div className="director-msg-img-section">
        <img
          src={Images.directorsMessage}
          alt="Director"
          className="director-msg-image"
        />
      </div>
    </div>
  );
};
