const Images = {
  logo: require("./Main_logo.png"),
  mockup: require("./mockup.png"),
  playstore: require("./playstore.png"),
  admission: require("./admission_pic.png"),
  doubleQuotes: require("./doubleQuotes.png"),
  director: require("./director.jpeg"),
  managingTrustee: require("./managingTrustee.jpeg"),
  trustee: require("./trustee.jpeg"),
  slider1: require("./slider_1.jpeg"),
  slider2: require("./slider_2.jpeg"),
  slider3: require("./slider_3.jpeg"),
  slider4: require("./slider_4.jpeg"),
  slider5: require("./slider_5.jpg"),
  slider6: require("./slider_6.jpg"),
  slider7: require("./slider_7.jpeg"),
  slider8: require("./slider_8.jpeg"),
  slider9: require("./slider_9.jpeg"),
  slider10: require("./slider_10.jpeg"),

  studentCollage: require("./studentCollage.jpeg"),
  directorsMessage: require("./directorsMesssage.jpeg"),
};

export default Images;
