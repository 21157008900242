import React from "react";
import { FaCalendarAlt, FaCheckCircle, FaTasks } from "react-icons/fa";
import { GiPartyFlags } from "react-icons/gi";
import TopHeading from "../../components/TopHeading";
import "./MiscellaneousStyles.css";

const TimeTableSection = () => {
  return (
    <>
      <h2 className="misc-title">
        <FaCalendarAlt />
        Timetable (English Medium)
      </h2>
      <div className="misc-timetable">
        <h3>Monday to Friday</h3>
        <table className="misc-table">
          <tbody>
            <tr>
              <td>First Bell</td>
              <td>07.10 a.m.</td>
            </tr>
            <tr>
              <td>Assembly / Prayer</td>
              <td>07.15 a.m. to 07.25 a.m.</td>
            </tr>
            <tr>
              <td>1st Period</td>
              <td>07.25 a.m. to 07.55 a.m.</td>
            </tr>
            <tr>
              <td>2nd Period</td>
              <td>07.55 a.m. to 08.30 a.m.</td>
            </tr>
            <tr>
              <td>3rd Period</td>
              <td>08.30 a.m. to 09.05 a.m.</td>
            </tr>
            <tr>
              <td>4th Period</td>
              <td>09.05 a.m. to 09.40 a.m.</td>
            </tr>
            <tr>
              <td>Recess</td>
              <td>09.40 a.m. to 10.00 a.m.</td>
            </tr>
            <tr>
              <td>5th Period</td>
              <td>10.00 a.m. to 10.35 a.m.</td>
            </tr>
            <tr>
              <td>6th Period</td>
              <td>10.35 a.m. to 11.10 a.m.</td>
            </tr>
            <tr>
              <td>7th Period</td>
              <td>11.10 a.m. to 11.40 a.m.</td>
            </tr>
            <tr>
              <td>8th Period</td>
              <td>11.40 a.m. to 12.15 p.m.</td>
            </tr>
            <tr>
              <td>Prayer / Dispersal</td>
              <td>12.20 p.m.</td>
            </tr>
          </tbody>
        </table>
        <h3>Saturdays</h3>
        <table className="misc-table">
          <tbody>
            <tr>
              <td>First Bell</td>
              <td>10.20 a.m.</td>
            </tr>
            <tr>
              <td>Assembly / Prayer</td>
              <td>10.20 a.m. to 10.30 a.m.</td>
            </tr>
            <tr>
              <td>1st Period</td>
              <td>10.35 a.m. to 11.10 a.m.</td>
            </tr>
            <tr>
              <td>2nd Period</td>
              <td>11.10 a.m. to 11.45 a.m.</td>
            </tr>
            <tr>
              <td>3rd Period</td>
              <td>11.45 a.m. to 12.20 p.m.</td>
            </tr>
            <tr>
              <td>4th Period</td>
              <td>12.20 p.m. to 12.55 p.m.</td>
            </tr>
            <tr>
              <td>Recess</td>
              <td>12.55 p.m. to 01.15 p.m.</td>
            </tr>
            <tr>
              <td>5th Period</td>
              <td>01.15 p.m. to 01.50 p.m.</td>
            </tr>
            <tr>
              <td>Prayer / Dispersal</td>
              <td>01.50 p.m.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 className="misc-title">
        <FaCalendarAlt />
        Timetable (Gujarati Medium)
      </h2>
      <div className="misc-timetable">
        <h3>Monday to Friday</h3>
        <table className="misc-table">
          <tbody>
            <tr>
              <td>Prayer</td>
              <td>12.10 p.m. to 12.20 p.m.</td>
            </tr>
            <tr>
              <td>1st Period</td>
              <td>12.25 p.m. to 01.00 p.m.</td>
            </tr>
            <tr>
              <td>2nd Period</td>
              <td>01.00 p.m. to 01.35 p.m.</td>
            </tr>
            <tr>
              <td>3rd Period</td>
              <td>01.35 p.m. to 02.10 p.m.</td>
            </tr>
            <tr>
              <td>4th Period</td>
              <td>02.10 p.m. to 02.45 p.m.</td>
            </tr>
            <tr>
              <td>Recess</td>
              <td>02.45 p.m. to 03.05 p.m.</td>
            </tr>
            <tr>
              <td>5th Period</td>
              <td>03.05 p.m. to 03.40 p.m.</td>
            </tr>
            <tr>
              <td>6th Period</td>
              <td>03.40 p.m. to 04.15 p.m.</td>
            </tr>
            <tr>
              <td>7th Period</td>
              <td>04.15 p.m. to 04.50 p.m.</td>
            </tr>
            <tr>
              <td>8th Period</td>
              <td>04.50 p.m. to 05.25 p.m.</td>
            </tr>
            <tr>
              <td>Prayer / Dispersal</td>
              <td>05.30 p.m.</td>
            </tr>
          </tbody>
        </table>
        <h3>Saturdays</h3>
        <table className="misc-table">
          <tbody>
            <tr>
              <td>First Bell</td>
              <td>07.10 a.m.</td>
            </tr>
            <tr>
              <td>Prayer</td>
              <td>07.10 a.m. to 07.20 a.m.</td>
            </tr>
            <tr>
              <td>1st Period</td>
              <td>07.20 a.m. to 07.45 a.m.</td>
            </tr>
            <tr>
              <td>2nd Period</td>
              <td>07.45 a.m. to 08.20 a.m.</td>
            </tr>
            <tr>
              <td>3rd Period</td>
              <td>08.20 a.m. to 08.55 a.m.</td>
            </tr>
            <tr>
              <td>4th Period</td>
              <td>08.55 a.m. to 09.30 a.m.</td>
            </tr>
            <tr>
              <td>Recess</td>
              <td>09.30 a.m. to 09.45 a.m.</td>
            </tr>
            <tr>
              <td>5th Period</td>
              <td>09.45 a.m. to 10.20 a.m.</td>
            </tr>
            <tr>
              <td>Dispersal</td>
              <td>10.20 a.m.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const CoCurricularActivitiesSection = () => {
  return (
    <>
      <h2 className="misc-title">
        <FaTasks />
        Co-curricular Activities
      </h2>
      <div className="misc-activities">
        <div className="misc-activities-section">
          <h3>STD. I TO V</h3>
          <ul>
            <li>
              <FaCheckCircle /> G.K. Quiz
            </li>
            <li>
              <FaCheckCircle /> Drawing / Colouring
            </li>
            <li>
              <FaCheckCircle /> Elocution
            </li>
            <li>
              <FaCheckCircle /> Maths Olympaid
            </li>
            <li>
              <FaCheckCircle /> Calligraphy
            </li>
            <li>
              <FaCheckCircle /> Collarge work
            </li>
            <li>
              <FaCheckCircle /> Action Song
            </li>
            <li>
              <FaCheckCircle /> Fancy Dress
            </li>
            <li>
              <FaCheckCircle /> Poem recitation
            </li>
            <li>
              <FaCheckCircle /> Library Week
            </li>
            <li>
              <FaCheckCircle /> Master Chef
            </li>
            <li>
              <FaCheckCircle /> Singing Song
            </li>
            <li>
              <FaCheckCircle /> Story Telling
            </li>
            <li>
              <FaCheckCircle /> Fashion Show
            </li>
          </ul>
        </div>
        <div className="misc-activities-section">
          <h3>STD. VI TO IX & XI-XII (Comm./Sci.)</h3>
          <ul>
            <li>
              <FaCheckCircle /> G.K. Quiz
            </li>
            <li>
              <FaCheckCircle /> Science Quiz
            </li>
            <li>
              <FaCheckCircle /> Drawing / Painting
            </li>
            <li>
              <FaCheckCircle /> Elocution
            </li>
            <li>
              <FaCheckCircle /> Maths Olympaid
            </li>
            <li>
              <FaCheckCircle /> Song
            </li>
            <li>
              <FaCheckCircle /> Debate
            </li>
            <li>
              <FaCheckCircle /> Essay Writing
            </li>
            <li>
              <FaCheckCircle /> Library Week
            </li>
            <li>
              <FaCheckCircle /> Master Chef
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const CelebrationsSection = () => {
  return (
    <>
      <h2 className="misc-title">
        <GiPartyFlags />
        Celebrations
      </h2>
      <ul className="misc-celebrations">
        <li>
          <FaCheckCircle /> Raksha Bandhan
        </li>
        <li>
          <FaCheckCircle /> Diwali
        </li>
        <li>
          <FaCheckCircle /> Janmashtami
        </li>
        <li>
          <FaCheckCircle /> Uttrayan
        </li>
        <li>
          <FaCheckCircle /> Vasant Panchami
        </li>
        <li>
          <FaCheckCircle /> Teacher's Day
        </li>
        <li>
          <FaCheckCircle /> Republic Day
        </li>
        <li>
          <FaCheckCircle /> Navratri
        </li>
        <li>
          <FaCheckCircle /> Christmas Celebration
        </li>
        <li>
          <FaCheckCircle /> Guru Purnima
        </li>
      </ul>
    </>
  );
};

const MiscellaneousSection = () => {
  return (
    <div className="misc-container">
      <div className="misc-section">
        <TimeTableSection />
      </div>
      <div className="misc-section">
        <CoCurricularActivitiesSection />
      </div>
      <div className="misc-section">
        <CelebrationsSection />
      </div>
    </div>
  );
};

const Miscellaneous = () => {
  return (
    <div id="Miscellaneous" className="miscellaneous-main-container">
      <TopHeading heading="Miscellaneous" />
      <MiscellaneousSection />
    </div>
  );
};

export default Miscellaneous;
