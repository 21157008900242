import React from "react";
import "./ContactUsStyles.css";

export const ContactUs = () => {
  return (
    <div id="ContactUs" className="main-container">
      <div className="inside-container">
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.0243640555964!2d73.16874207533805!3d22.352708879648976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc92f9806c16b%3A0xfbf423cc6a26b185!2sAtharva%20Vidyalaya!5e0!3m2!1sen!2sin!4v1719905068210!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Atharava Vidyalaya"
          ></iframe>
        </div>
        <div className="info-container">
          <h1>Get In Touch</h1>
          <p className="paraText">
            Send your queries & Inquires using the below form and we'll write
            back to you as soon as possible.
          </p>
          <div className="container">
            <div className="contact-form">
              <form
                action="https://formspree.io/f/movaqeyy"
                method="POST"
                className="contact-inputs"
              >
                <div className="text-container">
                  <p className="header-input-texts">Name*</p>
                  <input
                    className="input-style"
                    type="text"
                    name="username"
                    placeholder="Enter Name"
                    autoComplete="off"
                    required
                  />
                </div>

                <div className="text-container">
                  <p className="header-input-texts">Phone</p>
                  <input
                    className="input-style"
                    type="phone"
                    name="phone"
                    placeholder="Enter Phonenumber"
                    autoComplete="off"
                    required
                  />
                </div>

                <div className="text-container">
                  <p className="header-input-texts">Email</p>
                  <input
                    className="input-style"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    autoComplete="off"
                    required
                  />
                </div>

                <div className="text-container">
                  <p className="header-input-texts">Comment or Message</p>
                  <textarea
                    className="input-style2"
                    name="message"
                    cols="20"
                    rows="5"
                    placeholder="Type your message..."
                    autoComplete="off"
                    required
                  ></textarea>
                </div>

                <input type="submit" value="Send" className="buttonStyle" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
