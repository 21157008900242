import React from "react";
import { FaClipboardQuestion } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import TopHeading from "../../components/TopHeading";
import Images from "../../images";
import { ContactUs } from "../ContactUs";
import "./AdmissionsScreenStyles.css";

export const AdmissionsScreen = () => {
  return (
    <div id="admissions" className="admission-screen-main-container">
      <TopHeading heading="Admissions" />
      <div className="inner-container-admission">
        <div className="inner-text-container">
          <span className="inner-header-text">Additional Information</span>
          <div className="inner-down-text-container">
            <div className="pointer-text-container">
              <GoDotFill className="admission-logo-style" size={15} />
              <span>
                For admission enquiry contact the school front desk during
                weekdays between 8:00 a.m. to 2:00 p.m.
              </span>
            </div>
            <div className="pointer-text-container">
              <GoDotFill className="admission-logo-style" size={15} />
              <span>
                Admission will be made strictly on the basis of availably to the
                extent seats are available in each class.
              </span>
            </div>
            <div className="pointer-text-container">
              <GoDotFill className="admission-logo-style" size={15} />
              <span>
                The management's decision in this regard will be final. Please
                contact the reception for the answer.
              </span>
            </div>
          </div>
          <div className="admissions-inner-btn-container">
            <div
              className="admissions-item"
              onClick={() =>
                window.open(
                  "https://drive.google.com/file/d/1YXQIY2sLQbRd48ANqNXFvt-s4yARZCqI/view?usp=drive_link",
                  "_blank"
                )
              }
            >
              <div className="admissions-icon admission-bg-primary">
                <FaClipboardQuestion />
              </div>
              <div className="admission-btn-text">
                <h3>Admission Inquiry Form</h3>
                <p>Click Here</p>
              </div>
            </div>
          </div>
        </div>
        <img
          src={Images.slider10}
          className="admission-picture-style"
          alt="AdmissionImage"
        />
      </div>
      <ContactUs />
    </div>
  );
};
