import React from "react";
import Slider from "react-slick";
import "./SliderImagesStyles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Images from "../../images";

const SliderImages = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const images = [
    Images.slider4,
    Images.slider3,
    Images.slider2,
    Images.slider1,
    Images.slider5,
    Images.slider6,
    Images.slider7,
    Images.slider8,
    Images.slider9,
    Images.slider10,
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slider-item">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="slider-image"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderImages;
