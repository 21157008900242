import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail, MdPhoneAndroid } from "react-icons/md";
import TopHeading from "../../../components/TopHeading";
import { ContactUs } from "../ContactUs";
import "./ContactUsScreenStyles.css";

export const ContactUsScreen = () => {
  const handleCardClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div id="ContactUsScreen" className="contact-main-container">
      <TopHeading heading="Contact Us" />
      <div className="contact-cards-row g-4">
        <div
          className="contact-card-item"
          onClick={() =>
            handleCardClick("https://maps.app.goo.gl/89EwxhgvqH9Y8qih8")
          }
        >
          <div className="contact-card-icon">
            <FaLocationDot className="contact-icon" />
          </div>
          <div className="contact-text">
            <h3>Address</h3>
            <p>
              Atharava Vidyalaya, Guru Gobindsinhji Marg, Chhani, Vadodara,
              Gujarat 391740
            </p>
          </div>
        </div>
        <div
          className="contact-card-item"
          onClick={() =>
            handleCardClick("mailto:atharava_vidyalaya_chhani@yahoo.com")
          }
        >
          <div className="contact-card-icon">
            <MdEmail className="contact-icon" />
          </div>
          <div className="contact-text">
            <h3>Email Address</h3>
            <p>atharava_vidyalaya_chhani@yahoo.com</p>
          </div>
        </div>
        <div
          className="contact-card-item"
          onClick={() => handleCardClick("tel:+919638916655")}
        >
          <div className="contact-card-icon">
            <MdPhoneAndroid className="contact-icon" />
          </div>
          <div className="contact-text">
            <h3>Phone Number</h3>
            <p>(+91) 9638916655</p>
          </div>
        </div>
      </div>
      <ContactUs />
    </div>
  );
};
