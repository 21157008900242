import React from "react";
import "./FromTheDeskStyles.css";
import Images from "../../images";

const FromTheDesk = ({ name, designation }) => {
  return (
    <div className="principal-message">
      <div className="pm-centred-div">
        <div className="ftd-content">
          <h2>From The {designation}'s Desk</h2>
          <p className="principal-name">{name}</p>
          {/* <div className="quote-box">
            <div className="quote-box-left" />
            <div className="quote-box-right">
              <img alt="" src={Images.doubleQuotes} />
              <div className="quote-text">
                "The purpose of education is to make good human beings with
                skill and expertise….Enlightened human beings can be created by
                teachers."
              </div>
              <div className="quote-author">-DR. APJ ABDUL KALAM</div>
            </div>
          </div> */}
          <div className="ftd-message">
            <p>
              Every year Atharava touches to new heights. The school sets
              priority for the character formation and strives for excellence
              both in academics and non- academics disciplines. The school
              expects that the wards attend school regularly and punctually and
              maintain the standards of discipline that is required. Educational
              development is a joint venture of school authorities and the
              parents. Therefore it is requested to take a keen interest and
              contribute actively in terms of your time, talents and resources
              in achieving this goal.
            </p>
            <p>
              For a meaningful education, effective communication should
              co-exist between school and parents, this section offers you, the
              ideology of the school and provides you with complete information
              regarding the institution.I look forward to your whole hearted
              co-operation throughout the year.
            </p>
            <p>Hope to see you all soon- refreshed and rejuvenated.</p>
          </div>
        </div>
        <div className="ftd-image-container">
          <img
            loading="lazy"
            decoding="async"
            src={Images.director}
            className="attachment-full size-full wp-image-12261"
            alt=""
            sizes="(max-width: 547px) 100vw, 547px"
          />
          {/* <p>
            For a meaningful education, effective communication should co-exist
            between school and parents, this section offers you, the ideology of
            the school and provides you with complete information regarding the
            institution.I look forward to your whole hearted co-operation
            throughout the year.
          </p>
          <p>Hope to see you all soon- refreshed and rejuvenated.</p> */}
        </div>
      </div>
    </div>
  );
};

export default FromTheDesk;
