import React from "react";
import { FaChalkboard } from "react-icons/fa";
import { FaComputer } from "react-icons/fa6";
import { GiTrophyCup } from "react-icons/gi";
import { IoLibrary } from "react-icons/io5";
import "./SchoolFacilitiesStyles.css";

const SchoolFacilities = () => {
  return (
    <div className="facility-container">
      <div className="text-center mx-auto mb-5">
        <h1 className="mb-3">School Facilities</h1>
        <p>
          Primary objective of our school is excellence in academics. The
          curriculum is planned to achieve a balance between searches for
          understanding and knowledge of essential skills.
        </p>
      </div>
      <div className="facility-row g-4">
        <div className="col-lg-3 col-sm-6">
          <div className="facility-item">
            <div className="facility-icon bg-primary">
              <IoLibrary className="icon" />
            </div>
            <div className="facility-text">
              <h3>Library</h3>
              <p>
                Our well-stocked library provides a wide range of books,
                journals, and digital resources to support student learning and
                research.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="facility-item">
            <div className="facility-icon bg-success">
              <FaComputer className="icon" />
            </div>
            <div className="facility-text">
              <h3>Computer Lab</h3>
              <p>
                The computer lab is equipped with the latest technology and
                software, offering students hands-on experience in various
                digital skills.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="facility-item">
            <div className="facility-icon bg-warning">
              <GiTrophyCup className="icon" />
            </div>
            <div className="facility-text">
              <h3>Extracurricular Activities</h3>
              <p>
                We offer a variety of extracurricular activities, including
                sports, music, arts, and clubs, to help students develop diverse
                interests and skills.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="facility-item">
            <div className="facility-icon bg-info">
              <FaChalkboard className="icon" />
            </div>
            <div className="facility-text">
              <h3>Smart Class</h3>
              <p>
                Equipped with interactive whiteboards, our smart classrooms
                enhance the learning experience with engaging digital content.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolFacilities;
