import { useState } from "react";
import "./App.css";
import Home from "./screens/Home/Home";
import Header from "./screens/Header";
import Navbar from "./screens/Navbar";
import AboutUsScreen from "./screens/AboutUs";
import AdmissionsScreen from "./screens/Admissions";
import { ContactUsScreen } from "./screens/ContactUs";
import Academics from "./screens/Academics";
import CodeOfConduct from "./screens/CodeOfConduct";
import { SchoolInformation } from "./screens/SchoolInformation";
import { Examination } from "./screens/Examination";
import { Miscellaneous } from "./screens/Miscellaneous";
import Footer from "./screens/Footer";
import { Gallery } from "./screens/Gallery";
import { PressAndMediaScreen } from "./screens/PressAndMedia";

function App() {
  const [activeLink, setActiveLink] = useState("home");

  const renderScreen = () => {
    switch (activeLink) {
      case "home":
        return <Home />;
      case "aboutusscreen":
        return <AboutUsScreen />;
      case "admission":
        return <AdmissionsScreen />;
      case "contactus":
        return <ContactUsScreen />;
      case "academics":
        return <Academics />;
      case "codeOfConduct":
        return <CodeOfConduct />;
      case "schoolInformation":
        return <SchoolInformation />;
      case "examination":
        return <Examination />;
      case "miscellaneous":
        return <Miscellaneous />;
      case "gallery":
        return <Gallery />;
      case "pressAndMedia":
        return <PressAndMediaScreen />;
      default:
        return <></>;
    }
  };
  return (
    <div className="App">
      <Header />
      <Navbar {...{ activeLink, setActiveLink }} />
      {renderScreen()}
      <Footer {...{ setActiveLink }} />
    </div>
  );
}

export default App;
